<template><div class="problem">
	<div class="box"><div class="xgfb">
		<el-form :model="infoForm" :rules="rules"  ref="infoForm">
			<div class="qxmidm pb40">
				<span class="qxmidmtxt2"><b>*</b>标题</span>
				<el-form-item prop="title">
					<el-input v-model="infoForm.title" placeholder="输入用户名" style="width: 403px;"  auto-complete="off"></el-input>
				</el-form-item>
			</div>
			<div class="qxmidm pb26">
				<span class="qxmidmtxt2">内容</span>
				<el-form-item prop="content">
					<quill-editor v-model="infoForm.content"
					              ref="myQuillEditor"
					              class="editer"
					              :options="editorOption" @ready="onEditorReady($event)">
					       </quill-editor>
				</el-form-item>
			</div>
			<div class="qxmidm mt40">
				<el-button type="primary" class="ssbtn" style="width: 5.375rem;" @click="submitForm('infoForm')">保存</el-button>
			</div>
		</el-form>
		
	</div></div>
</div></template>

<script>
	import 'quill/dist/quill.core.css'
	import 'quill/dist/quill.snow.css'
	import 'quill/dist/quill.bubble.css'
	import { quillEditor } from 'vue-quill-editor'
export default {
    data(){
		return{
			infoForm: {
			     title: this.$route.query.title,
			     content:this.$route.query.content,
			    },
				id:this.$route.query.id,
				 editorOption: {},
			 //表单验证
			    rules: {
			     title: [
			      {required: true, message: '请输入标题', trigger: 'blur'}
			     ],
			     content: [
			      {required: true, message: '请输入详细内容', trigger: 'blur'}
			     ]
			    },
		}
	},//data
	computed: {
	   editor() {
	    return this.$refs.myQuillEditor.quill
	   }
	  },
	   mounted() {
		  
	     //初始化
	    },
	methods:{
		onEditorReady(editor) {
		   },
		submitForm(formName){
		 this.$refs[formName].validate((valid) =>{
			 if (valid){
				const that=this;
				let dataid;
				if(!that.id){
					dataid=""
				}else{
					dataid=that.id
				}
				let data={
					id:dataid,
					content:that.infoForm.content,
					title:that.infoForm.title,
				}
				this.$api.setQuestion(data).then(res =>{
					console.log('res',res)
					if(res.data.code==0){
						that.$message.success(res.data.msg);
						setTimeout(function(){
							that.$router.push('/problem')  
						},100)
					}
				})
			 }
		 })
		},//submitForm   
	},//methods
	components: {
	//使用编辑器
	   quillEditor
	  },
}
</script>

<style lang="less" scoped>
    .editer{height: 400px;}
</style>